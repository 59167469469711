var adsBanner = function () {
    var windowWidth = $(window).width();
    var containerWidth = $(".container").width();

    if (windowWidth > (containerWidth + 220)) {
        $(".advert-banner").show();

    } else {
        $(".advert-banner").hide();
    }
};

$(document).ready(function(){

	AOS.init({
		offset: 10,
		once: true,
	});

    adsBanner();
    $(window).resize(function () {
        adsBanner();
    });

});

  